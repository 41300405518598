@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@400;700&display=swap');

* {
  padding: 0;
  margin: 0;
  font-family: 'Almarai', sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  /* --bg-body: #F1FAFF; */
  --bg-body: #f5f8fa;
  --primary-color: #65469b;
  --primary-color-hover: #543687;
  --primary-color-opacity: rgba(101, 70, 155, 0.5);
  --primary-color-light: rgba(101, 70, 155, 0.2);
  --gray: #858688;
  --gray-hover: #727375;
  --green-color: #29CC39;
  --green-opacity-color: rgba(40, 205, 57, 0.3);
  --red-color: #DC3545;
  --red-opacity-color: rgba(220, 53, 69, 0.3);
  --yellow-color: #ffc107;
  --yellow-opacity-color: rgba(255, 193, 7, 0.3);
  --black-color: #1E1E1E;
  --black-color-opacity: rgba(30, 30, 30, 0.2);
  --timeing: 0.3s;
  --radius: 0.625rem;
  --color-blue: #9DC0D0;
  --red: #ff4d4f;
  --yellow: #ffc107;
}

body {
  overflow-x: hidden !important;
  direction: rtl;
  background-color: var(--bg-body) !important;
}



:where(.css-dev-only-do-not-override-xu9wm8).ant-input-lg {
  font-size: 14px;
  background-color: var(--bg-body);
  border: 3px solid var(--bg-body) !important;
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-input:focus,
:where(.css-dev-only-do-not-override-xu9wm8).ant-input:focus-within {
  border-color: #1677ff;
  box-shadow: none;
  outline: 0;
  border: 3px solid var(--primary-color) !important;

}

/* input Background */
:where(.css-dev-only-do-not-override-xu9wm8).ant-input-affix-wrapper>input.ant-input {
  background-color: var(--bg-body);
}

/* **************** Start Utility **************** */
.Triangles {
  position: absolute;
  bottom: -35px;
  width: 100%;
  height: 35px;
  display: flex;
  background-image: linear-gradient(135deg, var(--primary-color) 25%, transparent 25%),
    linear-gradient(225deg, var(--primary-color) 25%, transparent 25%);
  background-size: 50px 50px;
}

.title-head-special {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 50px 0;
}


.title-head-special div {
  font-weight: bolder;
  font-size: 30px;
  display: inline;
  position: relative;
  z-index: 100;
}

.title-head-special div::before {
  content: "";
  position: absolute;
  background-color: var(--primary-color-opacity);
  width: 100%;
  height: 7px;
  z-index: -1;
  border-radius: 50px;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.style-shadow-small {
  box-shadow: 0 0 10px rgba(31, 31, 31, 0.05)
}

.style-blob-homepage {
  width: 110px;
  height: 110px;
  transition: var(--timeing);
}

.style-icons-homepage {
  width: 60px;
  height: 60px;
  transition: var(--timeing);
}

.border-radius-contact-us-2 {
  border-radius: var(--radius) 0 0 var(--radius);
}

.border-radius-contact-us-1 {
  border-radius: 0 var(--radius) var(--radius) 0;
}

/* slider images */
:where(.css-dev-only-do-not-override-xu9wm8).ant-carousel .slick-dots li button {
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  padding: 0;
  color: transparent;
  font-size: 0;
  border: 0;
  border-radius: 5px;
  outline: none;
  background: var(--primary-color);
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.3s;
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-carousel .slick-dots li.slick-active button {
  background: var(--primary-color);
  opacity: 1;
}

/* landing img scals */
.style-landing-img {
  height: 300px;
  width: 450px;
}

/* **************** End Utility **************** */
.style-footer-media {
  display: flex;
}


.style-footer-media-link {
  color: var(--bg-body);
  font-size: 28px;
  background-color: var(--primary-color-hover);
  transition: 0.2s;
  padding: 12px;
  display: flex;
  border-radius: 50px;
  margin: 5px;
}

.style-footer-media-link-1:hover {
  color: #fff;
  background-color: #1D9BF0;
  transition: 0.2s;
}

.style-footer-media-link-2:hover {
  color: #fff;
  background-color: #4267B2;
  transition: 0.2s;
}

.style-footer-media-link-3:hover {
  color: #fff;
  background-color: #EA4335;
  transition: 0.2s;
}

.style-footer-media-link-4:hover {
  color: #fff;
  background-color: #161B22;
  transition: 0.2s;
}

.height-landing-page {
  min-height: 100vh;
}

.landing-text {
  font-size: 72px;
  color: var(--primary-color);
  transition: var(--timeing);
}

.scalc-text-landing {
  /* animation: animateLandingTextCard 10s infinite linear alternate; */
  font-size: 25px;

}

:where(.css-dev-only-do-not-override-xu9wm8).ant-drawer .ant-drawer-header {
  display: none;
  flex: 0;
  align-items: center;
  padding: 16px 24px;
  font-size: 16px;
  line-height: 1.5;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}

:where(.css-dev-only-do-not-override-xu9wm8).ant-drawer .ant-drawer-body {
  flex: 1;
  min-width: 0;
  min-height: 0;
  padding: 0px !important;
  overflow: auto;
}

.css-1kdykfj {

  min-width: 230px !important;
  width: 230px !important;

}

.text-title-icons-blobs {
  font-size: 16px;
  transition: var(--timeing);
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/* large */
@media (max-width:992px) {
  .border-radius-contact-us-2 {
    border-radius: var(--radius) var(--radius) 0 0;
  }

  .border-radius-contact-us-1 {
    border-radius: 0 0 var(--radius) var(--radius);
  }

}

/* medium */
@media (max-width:768px) {
  .height-landing-page {
    min-height: 75vh;
  }

  .style-blob-homepage {
    width: 95px;
    height: 95px;
    transition: var(--timeing);
  }

  .style-icons-homepage {
    width: 45px;
    height: 45px;
    transition: var(--timeing);
  }

  .style-landing-img {

    max-width: 100%;
  }

  .text-title-icons-blobs {
    font-size: 14px;
    transition: var(--timeing);
  }

}

/* small */
@media (max-width:576px) {}

/* Extra Small */

@media (max-width:450px) {
  .landing-text {
    font-size: 40px;
    transition: var(--timeing);
  }

  .style-landing-img {
    height: 150px;

  }

  .scalc-text-landing {
    font-size: 15px;

  }
}